
/* eslint-disable */
//import '@babel/polyfill';
import { saveContactInformation } from './contact';
import Cookies from 'js-cookie';
import { showAlert } from './alerts';

const contactForm = document.querySelector('#contactForm');

// **** CONTACT FORM **** //
if (contactForm) {
    contactForm.addEventListener('submit', async (e) => {
        e.preventDefault();

        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const subject = document.getElementById('subject').value;
        const phone = document.getElementById('phone').value;
        const message = document.getElementById('message').value;

        await saveContactInformation(name, email, subject, message, phone);

    });
}

// **** COOKIES **** / /
const consentPropertyName = 'denocool_cookies_consent';

const shouldShowPopup = () => !Cookies.get(consentPropertyName);
const acceptCookieStorage = () =>
    Cookies.set(consentPropertyName, true, { expires: 365 });
const declineCookieStorage = () =>
    Cookies.set(consentPropertyName, false, { expires: 365 });

window.onload = () => {
    const consentPopup = document.getElementById('consent-popup');
    const acceptBtn = document.getElementById('accept');
    const declineBtn = document.getElementById('decline');

    const acceptFn = (event) => {
        acceptCookieStorage();
        //location.reload();
        consentPopup.classList.add('hidden');
    };

    const declineFn = (event) => {
        declineCookieStorage();
        consentPopup.classList.add('hidden');
    };

    acceptBtn.addEventListener('click', acceptFn);
    declineBtn.addEventListener('click', declineFn);

    if (shouldShowPopup()) {
        setTimeout(() => {
            consentPopup.classList.remove('hidden');
        }, 5000);
    }

    // When first loaded or consent is false, no Cookies are allowed
    if (
        Cookies.get(consentPropertyName) === 'false' ||
        Cookies.get(consentPropertyName) === null
    ) {
        // This window property must be set before any calls to the ga() command queue are made, and it must be set on each page for which you want to disable Google Analytics measurement. If the property is not set or set to false then measurement will work as usual.
        //window['ga-disable-UA-xxxx'] = true;
    }
};
